/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "@ionic/angular/css/normalize.css";
@import "@ionic/angular/css/structure.css";
@import "@ionic/angular/css/typography.css";
@import "@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "@ionic/angular/css/padding.css";
@import "@ionic/angular/css/float-elements.css";
@import "@ionic/angular/css/text-alignment.css";
@import "@ionic/angular/css/text-transformation.css";
@import "@ionic/angular/css/flex-utils.css";

@import "theme/bos-select.scss";

.rt2-error {
  color: var(--ion-color-danger) !important;
  margin-left: 20px;  
}

.toolbar-title {
  text-align: left;
  padding-top: 12px;
  padding-right: 12px;
  padding-bottom: 12px;
  padding-left: 22px;
}

.box-button-start {
  flex: 1 auto;
  height: 40px;
  display: flex;
  justify-content: flex-start;
}

.header-button-box {
  display: flex;
  flex: 1;
  justify-content: flex-end;
}

.toolbar-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.header-left-buttons {
  margin-right: -18mm;
  margin-left: 10px;
}

.header-left-buttons ion-button {
  --color: var(--ion-color-light);
  margin-top: -2px;
}

.header-center-logo {
  flex: 1 auto;
  height: 40px;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.header-right-buttons {
  position: fixed;
  margin-left: 88%;
}

.refresh-icon {
  font-size: 25px;
  color: var(--ion-color-light);
  margin-right: 5mm;
}

// .toolbar-menu {
//   // font-size: 25px;
// }

// .toolbar-back {
//   // font-size: 25px;
// }

.logo {
  height: 100px;
  width: auto;
  margin: auto;
  display: block;
}

.header-has-back-button {
  display: flex;
  flex: 1 auto;
  justify-content: center;
  height: 40px;
}

.header-has-both-buttons {
  margin-right: -15mm;
}

.toolbar-dots {
  color: var(--ion-color-light);
  font-size: 20px;
  margin-right: 3mm;
}

.text-input {
  border-bottom: 2px solid var(--ion-text-color);
}

.tabs-md .tab-button[aria-selected="true"] .tab-button-text {
  transform: none;
}

ion-app.platform-ios12 ion-content {
  pointer-events: auto; /*fix scroll lock on ios12.2*/
}

.select-alert {
  .sc-ion-alert-md {
    max-height: 100%;
  }
}

ion-toolbar {
  --color: var(--ion-color-light);
  --background: black;
}

ion-tab-bar {
  --background: black;
  ion-label {
    --color: var(--ion-color-light);
  }
} 

ion-tab-button {
  // Icon and text
  --color: var(--ion-color-light);
  //–color-selected: blue;
  // Background
  //–background: white;
  //–background-focused: white;
  //–ripple-color: white;
  }

ion-popover {
  --color: var(--ion-text-color);
  --background: var(--ion-background-color);
  --width: 300px;
  margin-top: 2mm;
  padding: 2mm;
}

ion-popover ion-content {
 --background: var(--ion-background-color);
  padding: 2mm;
}

ion-content {
  --background: var(--ion-background-color);
  --color: var(--ion-text-color);
  --padding-top: 10px;
  --padding-start: 10px;
  --padding-end: 10px;
}

ion-textarea {
  min-height: 100px;
  max-height: 1200px;
  textarea {
    min-height: 100px;
    max-height: 1200px;
  }
}

::ng-deep textarea {
  min-height: 100px;
  max-height: 1200px;
}

.read-only-input-container {
  background-color: var(--ion-color-step-0);
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin-left: 3mm;
  margin-right: 3mm;
  border-radius: 2%;
  padding: 1mm;
  font-size: 8px;
  padding-bottom: 1mm;
}

.read-only-inputs {
  display: flex;
  flex-direction: row;
  height: 20px;
}

.read-only-label {
  width: 25%;
}

.read-only-data {
  font-size: 10px;
}

.read-only-values-container {
  --background: var(--ion-background-color);
  display: flex;
  flex-grow: 1;
  margin-left: 3mm;
  margin-right: 3mm;
  border-radius: 2%;
  padding: 1mm;
  font-size: 12px;
  padding-bottom: 1mm;
  flex-wrap: wrap;
}

.read-only-value {
  flex: 100%;
  margin-bottom: 10px;
}

.pending-data {
  font-size: 20px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.page-header-container {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 100%;
  justify-content: flex-start;
  align-items: stretch;
  align-content: normal;
}



//style="display: flex; flex-direction: row; align-items: center; justify-content: center; font-weight: bold; margin: auto"

.page-sub-header-container {
  margin-top: 3mm;
}

.page-header-bold {
  font-weight: bold;
}

.margin-top-10 {
  margin-top: 10px;
}

.margin-bottom-10 {
  margin-bottom: 10px;
}

.page-header-underline {
  text-decoration: underline;
}

.page-hr {
  border-bottom: 1px solid var(--ion-color-step-750) !important;
  min-width: 100%; 
}

.page-header-work-schedule-icon {
  color: var(--ion-color-danger); 
  font-size: 18px; 
  align-self: center; 
  justify-self: center; 
  margin-left: 3mm;
}

.page-header-large-icon-dsrs {
  font-size: 50px; 
  color: var(--ion-color-danger); 
  margin-top: 5mm;
}

.page-header-large-icon {
  font-size: 30px; 
  color: var(--ion-color-danger); 
}

.page-header-text {
  text-align: center;
  margin: auto;
  max-width: 70%;
}

.page-header-row-1 {
  font-size: 17px;
  color: var(--ion-text-color) !important;
}

.page-header-row-2 {
  font-size: 19px;
  font-weight: bold;
  color: var(--ion-text-color) !important;
}

.page-busy-spinner {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

ion-spinner {
  width: 56px;
  height: 56px;
  stroke: var(--ion-text-color) !important;
  fill: var(--ion-text-color) !important;
}

.page-header-row-3-up-to-date {
  text-align: center;
  font-size: 17px;
  color: var(--ion-text-color) !important;
}

.page-header-row-3-pending {
  text-align: center;
  font-size: 17px;
  color: var(--ion-color-warning) !important;
}

.page-header-fab-container {
  margin-bottom: 10mm;
  top: 5;
}

.page-header-time-icon {  
   font-size: 65px;   
}

.card-selected {
  border: 5px solid var(--ion-color-tertiary) !important;
}

.card {
  flex: 1;
  position: relative;
  text-align: center;
  background-color: var(--ion-color-light) !important;
  color: var(--ion-color-dark) !important;
  font-size: 17px;
  font-weight: normal;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding-top: 25px !important;
  padding-bottom: 20px !important;
  padding-left: 10px;
  padding-right: 10px;
}

.card-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  flex-grow: 1;
  justify-content: flex-start;
  margin: 0;
  padding: 0;
  padding-left: 5px;
  padding-right: 5px;
}

.card-item {
  display: flex;
  font-size: 17px;  
  margin: 0;
  padding: 0;
  margin-right: 10px;
}

.card-item-date {
  font-weight: bold;
}

.card-item-hours {
  color: rgb(1, 155, 245);
}

.card-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 100%;
  justify-content: flex-start;
  align-items: stretch;
  align-content: normal;
}

.card-text-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: stretch;
  align-content: normal;
}

.card-image-container {
  flex: 1;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-end;
  margin-right: 10px;  
}

.card-image {
  min-width: 80px;
  min-height: 80px;
  flex: 1;
  display: flex;
  justify-content: flex-end;
}

.card-image-icon {
  min-width: 80px;
  min-height: 80px;
  display: block;
  object-fit: cover;
}

.card-image-delete-icon {
  font-size: 20px; 
  color: var(--ion-color-danger) !important;
}

.card-image-desktop-gallery-icon {
  width: 100%;
  height: 100%;  
  position: absolute;
  z-index: 10;
  left: 0%;
  top: 0%;
}

.card-image-camera-icon {
    width: 40%;
    height: 100%;        
    position: absolute;
    z-index: 10;
    left: 5%;
    top: 0%;
}

.card-image-gallery-icon {
    width: 40%;
    height: 100%;    
    position: absolute;
    z-index: 10;
    left: 55%;
    top: 0%;
}

.card-in-progress {
  background-color: tan !important;
}

.card-submitted {
  background-color: var(--ion-color-light) !important;
}

.card-deleted {
  background-color: gray !important;
}

.card-work-order-number {
  font-size: 20px;
  font-weight: bold;
}

.card-ticket-number {
  font-size: 20px;
  font-weight: bold;
}

.card-status {
  color: var(--ion-color-danger);
  font-size: 18px;
  font-weight: bold;
}

.card-status-giver {
  font-size: 18px;
  font-weight: bold;
}

.card-due-due {
  color: var(--ion-color-success) !important;
}

.card-due-overdue {
  color: var(--ion-color-danger) !important;
}

.card-add-icon {
  font-size: 20px;   
}

.card-delete-icon {
  font-size: 20px;    
  color: var(--ion-color-danger) !important;    
}

.card-upload-status-icon {
  width: 20px;
  height: 20px;
}

.card-icon-status-pass {
  color: var(--ion-color-dark) !important;
  background-color: var(--ion-color-pass) !important;
}

.card-icon-status-fail {
  color: var(--ion-color-dark) !important;
  background-color: var(--ion-color-danger) !important;
}

.icon-bottom-left {  
  position: absolute;
  z-index: 10;
  left: 5px;
  bottom: 5px;
}

.icon-bottom-right {  
  position: absolute;
  z-index: 10;
  right: 5px;
  bottom: 5px;
}

.icon-upper-right {  
  position: absolute;
  z-index: 10;
  right: 5px;
  top: 5px;
}

.rt2-error-div {
  margin-bottom: 5px;
}

.add-create-header {
  justify-content: center; 
  display: flex;
}

.flex-row {
  display: flex; 
  flex-direction: row;  
}

.form-label {
  margin-bottom: 3px;
  font-size: 12px;
  width: 100%;
}

.form-input-old {
  margin: 3mm;
  color: var(--ion-text-color) !important;
}

.form-input {
  margin-top: 0px;
  margin-bottom: 0px;
  margin-left: 0px;
  margin-right: 0px;  
  background-color: var(--ion-color-step-50) !important;
  color: var(--ion-color-step-750) !important;
}

.form-input-card {
  margin-top: 0px;
  margin-bottom: 0px;
  margin-left: 10px;
  margin-right: 10px;    
  min-height: 30px !important;
  width: 100px !important;
  background-color: var(--ion-color-step-250) !important;
  color: var(--ion-color-step-750) !important;
}

.prv-card {
  display: flex;
  font-size: 20px; 
  width: 40%;
  height: 75px;
  background-color: var(--ion-color-light) !important;
  color: var(--ion-color-dark) !important;
  align-items: center;
  justify-content: center;
}

.prv-section-no-underline {
  display: flex;
  flex: 1;
  font-size: 14px;
  font-weight: bold;  
}

.prv-section {
  display: flex;
  flex: 1;
  font-size: 14px;
  font-weight: bold;
  text-decoration: underline;
}

.prv-section-center {
  align-items: center;
  justify-content: center;
  text-align: center;
  text-overflow: ellipsis;
}

.prv-row-with-bottom-margin {
  margin-bottom: 20px;
}

.prv-flex-row {
  display: flex; 
  flex: 1;
  flex-direction: row;  
  border: 1px solid var(--ion-color-step-750);
  align-items: center;
  //min-width: 300px;
}

.prv-checkbox {    
  display: flex;
  flex: 1;
  
  min-height: 30px !important;
}

.prv-select-error {
  border: 3px solid var(--ion-color-danger);
}

.prv-label-only {    
  text-overflow: ellipsis;
  flex-wrap: wrap;
  display: flex;
  flex: 1;
  font-size: 12px;
  font-weight: bold;
  vertical-align: middle;
  white-space: wrap;
  width: 100%;
  min-height: 30px !important;
}

.prv-label {    
  flex-wrap: wrap;
  display: flex;
  flex: 1;
  font-size: 12px;
  font-weight: bold;
  vertical-align: middle;
  padding-right: 0px;
  margin-right: 0px;
  white-space: wrap;
  width: 100%;
  min-height: 30px !important;
}

.prv-read-only {
  font-weight: bold;
  font-size: 12px;
  font-style: italic;
  display: flex;
  flex: 1;
  border-left: 1px solid var(--ion-color-step-750);
  min-height: 30px !important;
  margin-top: 0px;
  margin-bottom: 0px;
  margin-left: 0px;
  margin-right: 0px;  
  padding-left: 10px !important;
  //background-color: var(--ion-color-step-50) !important;
  color: var(--ion-color-step-750) !important;
  width: 100%;
  white-space: wrap;
  align-items: center;
}

.prv-input {
  display: flex;
  flex: 1;
  border-left: 1px solid var(--ion-color-step-750);
  min-height: 30px !important;
  margin-top: 0px;
  margin-bottom: 0px;
  margin-left: 0px;
  margin-right: 0px;  
  padding-left: 10px !important;
  background-color: var(--ion-color-light) !important;
  color: var(--ion-color-dark) !important;
  width: 100%;
}

.prv-input-date {
  display: flex;
  flex: 1;
  border-left: 1px solid var(--ion-color-step-750);
  justify-content: flex-start;    
  width: 100%;
}

.input-error {
  border: 3px solid var(--ion-color-danger);
}

.form-input-date {
  justify-content: flex-start;
}

.image-full-size {
    width: 100%;
    overflow: fill;
}

.custom-date-spinner {
  display: flex; 
  align-items: center; 
  justify-content: center;
}

.custom-date-spinner-date-button {
  color: var(--ion-text-color) !important;
  border-radius: 1%;
  font-size: 27px;
  font-weight: bold;
  padding: 3px;
}

.custom-date-spinner-arrow {
  font-size: 50px; 
  color: var(--ion-text-color) !important;
  align-self: center; 
  padding-left: 10px;
}

/////VIEW STUFF////// 
.view-text-container {
  display: flex;
  flex-direction: row;
  padding: 1mm;
  justify-content: space-between;
  flex-wrap: wrap;
}

.view-item-label {
  display: flex;
  margin-bottom: -3mm;
  font-size: 13px;
  margin-right: 25mm;
  text-decoration: underline;
}
.view-item-value {
  font-size: 16px;
}

.view-service-entries-container {
  background-color: var(--ion-color-light) !important;
  color: var(--ion-color-dark) !important;
  margin-left: 1mm;
  margin-right: 1mm;
  margin-bottom: 5mm;
  padding: 1mm;
  border-radius: 5%;
  box-shadow: 2px 5px 5px var(--ion-color-step-750) !important;
}
.view-se-item-container {
  display: flex;
  flex-direction: column;
  margin-right: 25%;
}
.view-service-entries-header {
  font-weight: bold;
}

// PTO page
.pto-row {
  display: flex;
  flex-direction: row;
  border-bottom: .5px solid var(--ion-color-step-750) !important;
}
.pto-label {
  margin-right: 3mm;
  min-width: 250px;
}
.sick-container {
  background-color: var(--ion-color-light) !important;
  color: var(--ion-color-dark) !important;
  padding: 10px;
  border-radius: 10px;
  box-shadow: 2px 5px 5px var(--ion-color-step-750) !important;
}
.vacation-container {
  background-color: var(--ion-color-light) !important;
  color: var(--ion-color-dark) !important;
  padding: 10px;
  border-radius: 10px;
  box-shadow: 2px 5px 5px var(--ion-color-step-750) !important;
}
.pto-card-header {
  font-size: 19px;
  margin-bottom: 0;
}

.signature {
  width: 100%;
}

.new-signature 
{    
    position: relative;     
    border: 1px solid #c4caac;
}

.new-signature-canvas {
    width: 100%;
    height: 150px;
}

.flex-center-content {
  align-items: center;
  align-content: center;
}

.time-sheet-header {
  font-weight: bold;
}

.active-menu-item {
  --background: var(--ion-color-primary-contrast);
  border-radius: 8px;
  margin: 0 8px;
  ion-label {
    color: var(--ion-color-primary);
  }
}
